import React from 'react';
import styled from 'styled-components';
import moment from 'moment';
import H3 from '../Text/H3';
import Logo from '../Logo';
import Navigation from '../Text/Navigation';
import Row from '../Row';
import { formatAmount } from '../helpers';

export const Container = styled.div`
  width: 100%;
  max-width: 335px;
  height: 100%;
  display: flex;
  flex-direction: column;
  position: relative;
`;


function UserSubscriptions({ drops= [] }) {
  return (
    <Container>
      <Logo style={{ marginTop: 52, marginBottom: 60 }} />
      <H3 style={{
        color:'black', marginBottom: 27, textAlign:'left',
      }}
      >Receipts</H3>
      {
        drops.map(({
          amount, name, date,
        }, index) => <Row style={{ justifyContent: 'space-between', marginTop: index !== 0 ? 50 : 0 }} key={date}>
          <Navigation style={{ color:'#747474' }}>{name}</Navigation>
          <Navigation style={{ color:'#747474' }}>{moment(date).format('M/D/YYYY')}</Navigation>
          <Navigation style={{ color:'black' }}>${formatAmount(amount / 100)}</Navigation>
        </Row>)
      }
    </Container>
  );
}

export default UserSubscriptions;
