import React from 'react';
import styled from 'styled-components';
import Body from '../Text/Body';
import Button from '../Button';
import Link from '../Link';
import Flexor from '../Flexor';
import Logo from '../Logo';
import Column from '../Column';
import robot from './robot.svg';

export const Container = styled.div`
  width: 100%;
  max-width: 335px;
  height: 100%;
  display: flex;
  flex-direction: column;
  position: relative;
`;

const Title = styled.pre`
  font-family: Manrope;
  font-style: normal;
  font-weight: normal;
  font-size: 48px;
  line-height: 66px;
  letter-spacing: 0.01em;
  color: #000000;
  margin: 0;
  text-align: left;
  white-space: initial;
`;

function Intro({ next }) {
  return (
    <Container>
      <Logo style={{ marginTop: 52, marginBottom: 20 }} />
      <Flexor flex="1 0 20px" />
      <Column style={{ alignItems:'flex-start' }}>
        <img style={{ alignSelf: 'flex-start' }} src={robot} />
        <Title>
          {`      Anti Bot
      Giveaway
      Platform`}
        </Title>
        <Flexor flex="1 1 100px" />
      </Column>
      <Button onClick={next} style={{ marginBottom: 21 }} label="FIND A DROP" />
      <Link style={{ marginBottom: 33 }} label="DROP WITH US" />
    </Container>
  );
}

export default Intro;
