import React from 'react';
import styled from 'styled-components';
import { navigate } from 'gatsby';
import { connect } from 'react-redux';
import Intro from '../Intro';
import Menu from '../Menu';
import UserSubscriptions from '../UserSubscriptions';

const Container = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  margin: auto;
  justify-content: center;
  position: relative;
  background-color: white;
  @media screen and (min-width: 500px){
    max-height: 700px;
  }
`;

function Home({ user, accountData }) {
  const loggedIn = user && !user.isAnonymous;
  const drops = accountData?.drops;
  return (
    <Container>
      <Menu />
      {loggedIn ? (<UserSubscriptions drops={drops} />) : <Intro next={() => navigate('/drops')} />}
    </Container>
  );
}

const mapStateToProps = (state) => ({ ...state.userReducer });

export default connect(mapStateToProps)(Home);
